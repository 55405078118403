<template>
  <div class="document__unit-title">
    <template v-if="!parent">
      <h2 class="title fs-20 fs-md-28 fw-500">
        <template v-if="isNumbered(unit.title.type)">
          <div :id="'doc-title-' + count"></div>
          {{ count + '.' }}
        </template>
        {{ unit.title.h }}
      </h2>
    </template>
    <template v-else>
      <h2 class="title fs-16 fs-md-20 fw-500">
        <template v-if="isNumbered(unit.title.type)">
          <div :id="'doc-title-' + count"></div>
          <template v-if="parent">{{ parent }}</template>{{ count + '.' }}
        </template>
        {{ unit.title.h }}
      </h2>
    </template>
  </div>
</template>
<script>
export default {
  props: ['unit', 'parent', 'count'],
  methods: {
    isNumbered (type) {
      return type === 'numbered'
    }
  }
}
</script>
