<template>
  <div class="row">
    <div class="col-12 col-xl-6" v-for="(item, i) in slicedSubjects" :key="'subject-col-' + i">
      <div class="wrapper d-flex flex-column">
        <a :href="'#doc-title-' + getCorrectIndex(i, t)" v-smooth-scroll="{ duration: 1000, offset: -80 }" @click="highlightSection(getCorrectIndex(i, t))" class="link link--td-none fs-14 fs-md-18 fw-500 mb-2" v-for="(text, t) in item.arr" :key="'subject-item-' + t">
          {{ getCorrectIndex(i, t) + '. ' }}
          {{ text }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['subjects'],
  methods: {
    getHalf () {
      return Math.ceil(this.subjects.length / 2)
    },
    getCorrectIndex (iParent, iChild) {
      let index = null
      if (iParent === 0) {
        index = iChild + 1
      } else {
        index = (iChild + this.getHalf()) + 1
      }
      return index
    }
  },
  computed: {
    slicedSubjects () {
      return [{ arr: this.subjects.slice(0, this.getHalf()) }, { arr: this.subjects.slice(this.getHalf()) }]
    }
  }
}
</script>
