<template>
  <div class="document__unit-table">
    <table class="table">
      <thead>
        <tr class="table-head">
          <th v-for="(item, i) in tContent.thead" :key="`thead-${i}`">
            <div class="table-wrapper">
              <p class="text fs-12 fw-600 ta-left">{{ item }}</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="table-body" v-for="(item, i) in tContent.tbody" :key="`tr-${i}`">
          <td v-for="(childItem, a) in item" :key="`td-${a}`">
            <div class="table-wrapper">
              <p class="text fs-12 fw-600 ta-left">{{ childItem }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ['unit', 'parent', 'count'],
  computed: {
    tContent: function () {
      return this.unit.table.content
    }
    // countCols: function () {
    //   return this.unit.table.content.thead.length
    // }
  },
  methods: {
    isNumbered (type) {
      return type === 'numbered'
    }
  }
}
</script>
