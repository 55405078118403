<template>
  <ul class="list list--numbered" :class="'list--numbered-' + theme">
    <li v-for="(li, l) in list" :key="'option-list-' + l">
      <i class="fs-14 fw-600 lh-20">{{ fullNumber(l) + '.' }}</i>
      <span class="text tc-silver fs-14 lh-20"><span class="tc-night fw-600" v-if="isInObj('title', li)">«{{ li.title }}» </span>{{ li.text }}</span>
      <template v-if="isInObj('sub', li)">
        <list-definition :list="li.sub" theme="default" :parent="fullNumber(l)" class="ps-4"/>
      </template>
      <template v-if="isInObj('list', li)">
        <div class="ps-5">
          <template v-if="li.list.type === 'dot'">
            <list-dot :list="li.list.ul" :theme="li.list.theme"/>
          </template>
        </div>
      </template>
    </li>
  </ul>
</template>
<script>
import ListDot from '@/components/lists/ListDot'
export default {
  name: 'ListDefinition',
  components: {
    ListDot
  },
  props: ['theme', 'list', 'parent'],
  methods: {
    isInObj (key, obj) {
      return key in obj
    },
    fullNumber (i) {
      return this.parent + '.' + (i + 1)
    }
  }
}
</script>
