<template>
  <div class="document__content">
    <div v-for="(section, s) in content" :key="'doc-sec-' + s" :class="'document__' + section.type">
      <div v-for="(unit, u) in section.units" :key="'doc-unit-' + u"
           class="document__unit">
        <template v-if="isInObj('subjects', unit)">
          <unit-subject :subjects="unit.subjects"/>
        </template>
        <template v-if="isInObj('title', unit)">
          <unit-title :unit="unit" :parent="parent" :count="count()[s]"/>
        </template>
        <template v-if="isInObj('text', unit)">
          <unit-text :unit="unit" :parent="parent" :count="count()[s] + '.'"/>
        </template>
        <template v-if="isInObj('table', unit)">
          <unit-table :unit="unit" :parent="parent" :count="count()[s] + '.'"/>
        </template>
        <template v-if="isInObj('list', unit)">
          <div class="document__unit-list">
            <template v-if="unit.list.type === 'dot'">
              <comp-list-dot :list="unit.list.ul" :theme="unit.list.theme"/>
            </template>
            <template v-if="unit.list.type === 'numbered'">
              <comp-list-numbered :list="unit.list.ul" :theme="unit.list.theme"/>
            </template>
            <template v-if="unit.list.type === 'definition'">
              <comp-list-definition :list="unit.list.ul" :theme="unit.list.theme" :parent="count()[s]"/>
            </template>
          </div>
        </template>
        <template v-if="isInObj('sub', unit)">
          <doc-section :content="unit.sub" :parent="numFromParent(parent, count()[s])"/>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import UnitSubject from '@/components/docs/units/UnitSubject'
import UnitTitle from '@/components/docs/units/UnitTitle'
import UnitText from '@/components/docs/units/UnitText'
import UnitTable from '@/components/docs/units/UnitTable'
import CompListDot from '@/components/lists/ListDot'
import CompListNumbered from '@/components/lists/ListNumbered'
import CompListDefinition from '@/components/lists/ListDefinition'
export default {
  name: 'DocSection',
  props: {
    content: {
      type: Array,
      required: true
    },
    parent: {
      required: false
    }
  },
  components: {
    UnitSubject, UnitTitle, UnitText, UnitTable, CompListDot, CompListNumbered, CompListDefinition
  },
  methods: {
    isInObj (key, obj) {
      return key in obj
    },
    isNumbered (type) {
      return type === 'numbered'
    },
    numFromParent (par, cs) {
      let result = ''
      if (par !== undefined) {
        result = par
      }
      return result + cs + '.'
    },
    count () {
      const content = this.content
      const anchorArr = []
      let counter = 0

      for (let i = 0; i < content.length; i++) {
        for (let x = 0; x < content[i].units.length; x++) {
          if ('title' in content[i].units[x]) {
            if (content[i].units[x].title.type === 'numbered') {
              counter++
            }
          }
        }
        anchorArr.push(counter)
      }
      return anchorArr
    }
  }
}
</script>
