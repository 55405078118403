<template>
  <ul class="list list--numbered" :class="'list--numbered-' + theme">
    <li class="mb-2" v-for="(li, l) in list" :key="'option-list-' + l">
      <i class="fs-14 lh-20">{{ l + 1 + '.' }}</i>
      <span class="tc-silver fs-14 lh-20">{{ li }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  props: ['theme', 'list'],
  methods: {
    setColor (item, theme) {
      let result = 'tc-silver'
      if ('highlighted' in item) result = 'tc-' + theme
      return result
    }
  }
}
</script>
