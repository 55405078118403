<template>
  <ul class="list list--dot" :class="'list--dot-' + theme">
    <li v-for="(li, i) in list" :key="'doc-list-' + i">
      <p class="text tc-silver fs-14 lh-20">{{ li }}</p>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    theme: {
      type: String
    }
  }
}
</script>
