<template>
  <div class="document__unit-text">
    <p class="text tc-silver fs-14 fs-md-16 fw-400 lh-17">
      <template v-if="isNumbered(unit.text.type)">
        <template v-if="parent">{{ parent }}</template>{{ count + '.' }}
      </template>
      {{ unit.text.p }}
    </p>
  </div>
</template>
<script>
export default {
  props: ['unit', 'parent', 'count'],
  methods: {
    isNumbered (type) {
      return type === 'numbered'
    }
  }
}
</script>
